import React, { Component } from 'react';


import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { ClipLoader } from 'react-spinners';

import Card from './Card';


const API_KEY =`${process.env.REACT_APP_API_KEY}`;



class Home extends Component {

  constructor(props) {
    super(props);
    this.state = {
      cards: [],
      loading: true
    };
  };

  componentDidMount() {
    fetch('https://api.airtable.com/v0/appmgaLXPIr9ZGQps/Table%201?api_key=' + API_KEY)
    .then((resp) => resp.json())
    .then(data => {
       this.setState({ cards: data.records, loading: false });
       console.log(data.records);
    }).catch(err => {
      // Error :(
    });
  }




  render() {
    return (
      (this.state.loading ? 
      <div className='sweet-loading'>
        <ClipLoader
          className='override'
          sizeUnit={"px"}
          size={150}
          color={'#123abc'}
          loading={this.state.loading}
        />
      </div> 

      :

      <div className="Home">
        
        

        <div className="container">

          <div className="row align-items-center justify-content-between">
            <div className="col-12 col-md-8 col-lg-7">
              <h2 className="py-3">Welcome to the Social Proof Gallery</h2>
              <p className="lead py-3 text-secondary">Find inspiration to grow your business with this collection of the best examples of social proof done right. Helpfully collected, categorised and explained by the author of the <a href="https://www.socialproofhandbook.com">Social Proof Handbook</a>.</p>
            
              

            </div>

          </div>

          <div className="row align-items-center d-flex align-items-stretch justify-content-start">
            
              {this.state.cards.map(card => <Card {...card} /> )}
              
            
          </div>
        </div>


      </div>
      )
    );
  }
}

export default Home;

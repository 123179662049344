import React, { Component } from 'react';

import {Helmet} from "react-helmet";


import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { UncontrolledButtonDropdown, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';


import Card from './Card';
import Home from './Home';
import Category from './Category';
import Example from './Example';
import Results from './Results';

var magicMoment = [];
var format = [];
var industry = [];

const API_KEY =`${process.env.REACT_APP_API_KEY}`;

  function findFilters(item) {

    
    magicMoment.push(item.fields.magicMoment);
    format.push(item.fields.format);
    industry.push(item.fields.industry);

  
  
};




class App extends Component {


  
  
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      dropdownOpen: false,
      modal: true,
      magicMoments: [],
      formats: [],
      industries: []
      
    };
  }

  toggle() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
      modal: !this.state.modal
    });
  }


  componentDidMount() {

    

    window.location.pathname == "/" ? this.setState({modal:true}) : this.setState({modal:false});

    fetch('https://api.airtable.com/v0/appmgaLXPIr9ZGQps/Table%201?api_key=' + API_KEY)
    .then((resp) => resp.json())
    .then(data => {

      data.records.forEach(findFilters);
      var magicMomentsArray = [...new Set(magicMoment)];
      var formatsArray = [...new Set(format)];
      var industriesArray = [...new Set(industry)];

       this.setState({ magicMoments: magicMomentsArray.sort(), formats: formatsArray.sort(), industries: industriesArray.sort(), loading: false });
       



    }).catch(err => {
      // Error :(
    });
  }



  render() {
    return (
      <div className="App">

                <Helmet>
          <meta name="twitter:card" content="summary_large_image"/>
         <meta name="twitter:site" content="@louisnicholls_"/>
         <meta name="twitter:creator" content="@louisnicholls_"/>
         <meta name="twitter:title" content="Social Proof Handbook"/>
         <meta name="twitter:description" content="The best examples of social proof on the web."/>
         <meta name="twitter:image" content="https://www.socialproofhandbook.com/rainbow_files/undraw_finance_0bdk.png"/>
        </Helmet>
        
        <Router>
          

        <div>
        
        <nav className="navbar justify-content-between pt-3">
          <Link to="/" className="navbar-brand text-dark"><strong>The Social Proof Gallery</strong></Link>
          

            <div>
              <ul className="navbar-nav">
                
                <li className="nav-item">
                  <span className="nav-link disabled">Filter by:</span>
                </li>

                
              </ul>
              <UncontrolledButtonDropdown outline color="primary" className="mr-2 my-1">
                    <DropdownToggle outline color="primary" caret>
                      Magic Moments
                    </DropdownToggle>
                    <DropdownMenu>
                      {this.state.magicMoments.map(card => <DropdownItem tag={Link} to={"/magicMoment/" + card}> {card} </DropdownItem> )}
                      
                    </DropdownMenu>
                  </UncontrolledButtonDropdown>
              

              <UncontrolledButtonDropdown className="ml-sm-2 my-1">
                    <DropdownToggle outline color="danger" caret>
                      Formats
                    </DropdownToggle>
                    <DropdownMenu>
                      {this.state.formats.map(card => <DropdownItem tag={Link} to={"/format/" + card}> {card} </DropdownItem> )}
                      
                      
                    </DropdownMenu>
                  </UncontrolledButtonDropdown>

                  <UncontrolledButtonDropdown className="ml-sm-2 my-1">
                    <DropdownToggle outline color="success" caret>
                      Industry
                    </DropdownToggle>
                    <DropdownMenu>
                      {this.state.industries.map(card => <DropdownItem tag={Link} to={"/industry/" + card}> {card} </DropdownItem> )}
                      
                    </DropdownMenu>
                  </UncontrolledButtonDropdown>
              </div>
            
          
          
          
          
        </nav>
       

        <Route exact path="/" component={Home} />

        <Route exact path="/results" component={Results} />

        <Route exact path="/:categoryType/:category" component={Category} />

        <Route exact path="/post/:id" component={Example} />
        
      
         <Modal size="lg" centered="true" isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
          <ModalHeader toggle={this.toggle}>Learn how to boost your conversions with social proof...</ModalHeader>
          <ModalBody>
            <p>Find inspiration to grow your business with this collection of the best examples of social proof done right.</p>
            <p>Choose your industry or the part of your marketing funnel you want to improve, and see how brands similar to yours are using social proof to get better results.</p>
          </ModalBody>
          <ModalFooter>
            Search by: 
            {' '}
            <UncontrolledButtonDropdown className="ml-sm-2 my-1">
                    <DropdownToggle color="success" caret>
                      Industry
                    </DropdownToggle>
                    <DropdownMenu>
                      {this.state.industries.map(card => <DropdownItem onClick={this.toggle} tag={Link} to={"/industry/" + card}> {card} </DropdownItem> )}
                      
                    </DropdownMenu>
                  </UncontrolledButtonDropdown>

                  <UncontrolledButtonDropdown  color="primary" className="mr-2 my-1">
                    <DropdownToggle color="primary" caret>
                      Funnel Stage
                    </DropdownToggle>
                    <DropdownMenu>
                      {this.state.magicMoments.map(card => <DropdownItem onClick={this.toggle} tag={Link} to={"/magicMoment/" + card}> {card} </DropdownItem> )}
                      
                    </DropdownMenu>
                  </UncontrolledButtonDropdown>
            
          </ModalFooter>
        </Modal>

        </div>
        
        </Router>


        


      </div>
    );
  }
}

export default App;

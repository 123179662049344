import React, { Component } from 'react';


import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { ClipLoader } from 'react-spinners';

import ExampleCard from './ExampleCard';

const API_KEY =`${process.env.REACT_APP_API_KEY}`;

class Example extends Component {

  constructor(props) {
    super(props);
    this.state = {
      cards: [],
      loading: true

    };
  };

  componentDidMount() {
    fetch('https://api.airtable.com/v0/appmgaLXPIr9ZGQps/Table%201?api_key=' + API_KEY)
    .then((resp) => resp.json())
    .then(data => {

      

       this.setState({ cards: data.records, loading: false });
       console.log(this.state);

       

    }).catch(err => {
      // Error :(
    });
  }




  render() {
    return (

      (this.state.loading ? 
      <div className='sweet-loading'>
        <ClipLoader
          className='override'
          sizeUnit={"px"}
          size={150}
          color={'#123abc'}
          loading={this.state.loading}
        />
      </div> 

      :
      <div className="Home">
        


        <div className="container">
          
            
              {this.state.cards.filter(card => card.id === this.props.match.params.id).map(card => <ExampleCard {...card} /> )}
              
            
          
        </div>


      </div>
      )
    );
  }
}

export default Example;

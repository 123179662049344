import React, { Component } from 'react';


import { BrowserRouter as Router, Route, Link } from "react-router-dom";



class Card extends Component {
  render() {
    return (

     <div className=" col-md-6 col-lg-4 col-12 d-flex align-items-stretch">

     
     <Link className="d-flex align-items-stretch align-self-stretch" to={'/post/' + this.props.id }>

     

      <div className={"card-block card-item align-self-stretch my-3 rounded bg-white p-0 align-items-stretch card-shadow " + this.props.fields.published}>
        <img className="card-img-top mt-0" src={this.props.fields.image[0].thumbnails.large.url}/>
        <div className="card-body rounded bg-white text-left pl-3 pr-3 d-flex justify-content-between">
          <h4 className="card-title text-dark pt-3 pb-0">{this.props.fields.title}</h4>
          <img className=" rounded-circle border-white" style={{borderStyle: "solid", borderRadius: "50%", height: 80, marginTop: "-40px", borderColor: "white", borderWidth: "10px!important", float:"1"}} src={this.props.fields.logo[0].thumbnails.large.url}/>
          
        </div>
        <div className="card-body align-self-stretch rounded bg-white text-left p-3">
          
          <span className="badge badge-primary my-1 mr-1">{this.props.fields.magicMoment}</span>
          <span className="badge badge-danger my-1 mr-1">{this.props.fields.format}</span>
          
          <span className="badge badge-info my-1 mr-1">{this.props.fields.effect}</span>
          <span className="badge badge-warning my-1 ">{this.props.fields.source}</span>
        </div>
      </div>
      </Link>
      </div>
      
    );
  }
}

export default Card;

import React, { Component } from 'react';


import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { ClipLoader } from 'react-spinners';

import Card from './Card';

const API_KEY =`${process.env.REACT_APP_API_KEY}`;

class Category extends Component {

  constructor(props) {
    super(props);
    this.state = {
      cards: [],
      loading: true

    };
  };

  componentDidMount() {
    fetch('https://api.airtable.com/v0/appmgaLXPIr9ZGQps/Table%201?api_key=' + API_KEY)
    .then((resp) => resp.json())
    .then(data => {
      console.log(this.props.match.params.categoryType);

      for (var i = 0; i < data.records.length; i++) {


        var categoryToFilter = this.props.match.params.categoryType;

        for (const value of Object.keys(data.records[i].fields)) {

          if(value == categoryToFilter) {
            console.log("something");
            console.log(value);
            data.records[i].fields.filterBy = data.records[i].fields[value]
          };
          
        };
        
      };

       this.setState({ cards: data.records, loading: false });

       console.log(this.state);

       

    }).catch(err => {
      // Error :(
    });
  }




  render() {
    return (

      (this.state.loading ? 
      <div className='sweet-loading'>
        <ClipLoader
          className='override'
          sizeUnit={"px"}
          size={150}
          color={'#123abc'}
          loading={this.state.loading}
        />
      </div> 

      :
      <div className="Home">
        


        <div className="container">
          <div className="row align-items-center d-flex align-items-stretch justify-content-start">
            
              {this.state.cards.filter(card => card.fields.filterBy === this.props.match.params.category).map(card => <Card {...card} /> )}
              
            
          </div>
        </div>


      </div>
      )
    );
  }
}

export default Category;

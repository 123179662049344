import React, { Component } from 'react';


import { BrowserRouter as Router, Route, Link } from "react-router-dom";

import {Helmet} from "react-helmet";





class ExampleCard extends Component {


  componentDidMount() {
    window.scrollTo(0,0);
  }


  render() {
    return (

      <div className="row align-items-top justify-content-between">

        <Helmet>
          <meta name="twitter:card" content="summary_large_image"/>
         <meta name="twitter:site" content="@louisnicholls_"/>
         <meta name="twitter:creator" content="@louisnicholls_"/>
         <meta name="twitter:title" content={this.props.fields.title}/>
         <meta name="twitter:description" content="The best examples of social proof on the web."/>
         <meta name="twitter:image" content={this.props.fields.image[0].url}/>
        </Helmet>


         <div className=" col-md-8 col-lg-5 col-12 ">

         

          <div className="card-block bg-white rounded card-shadow p-0 mx-0 my-3">
            <div className="card-header pt-3 border-none px-3 rounded-top">
              <h2 className="text-dark p-0">{this.props.fields.title}</h2>
            </div>

            <div className="card-body bg-white px-3 pb-3 rounded">
              <img className=" rounded-circle border-white float-right" style={{borderStyle: "solid", borderRadius: "50%", height: 100, marginTop: "-50px", borderColor: "white", borderWidth: "10px!important", float:"1"}} src={this.props.fields.logo[0].thumbnails.large.url}/>
              <h5 className="pt-4 pb-3"><span className="small text-danger"><i className="fas fa-wrench"></i></span> The Challenge:</h5>
              <p className=" text-secondary pb-2">{this.props.fields.challenge}</p>
              <h5 className="py-3"><span className="small text-success"><i className="fas fa-trophy"></i></span> The Solution:</h5>
              <p className=" text-secondary pb-2">{this.props.fields.solution}</p>
              <h5 className="py-3"><span className="small text-primary"><i className="fas fa-bolt"></i></span> Key Takeaways:</h5>
              <p className=" text-secondary pb-2">{this.props.fields.takeaway}</p>
            </div>

           
          </div>
          <div className="row justify-content-between">

            <div className="col-sm-8 col-md-8 col-lg-7 col-12 ">
              <div className="card-block bg-white rounded card-shadow mx-0 my-3">
                <p className=" text-secondary">Magic Moment: <span className="badge badge-primary float-right">{this.props.fields.magicMoment}</span></p>
                <p className=" text-secondary">Format: <span className="badge badge-danger ml-3 float-right">{this.props.fields.format}</span></p>
                <p className=" text-secondary">Effect: <span className="badge badge-info float-right">{this.props.fields.effect}</span></p>
                <p className=" text-secondary">Source: <span className="badge badge-warning float-right">{this.props.fields.source}</span></p>
                <p className=" text-secondary">Industry: <span className="badge badge-primary float-right">{this.props.fields.industry}</span></p>
              </div>
            </div>
          </div>



          
          
          </div>

          <div className=" col-md-12 col-lg-7 col-12 no-gutters">
            <a href={this.props.fields.image[0].url} >
              <img className="img-fluid m-0 p-0 zoom-image sticky-top" src={this.props.fields.image[0].url}/>
            </a>
          </div>


          <div className="fixed-bottom bg-1 text-center p-2 text-white">Learn the best way to <strong>grow your business by 2-10%</strong> with social proof today <a href="https://www.socialproofhandbook.com" className="ml-3 multi-pulse color-change btn btn-sm btn-white">boost my sales</a></div>
      </div>

      
    );
  }
}

export default ExampleCard;
